import React from "react";
import { User } from "../../models/user";
import { Chat } from "./Chat/Chat";
import { Editor } from "./Editor/Editor";
import { Menu } from "./Menu/Menu";
import "./Room.scss";
import { RoomFocus } from "./RoomFocus/RoomFocus";
import { RoomHeader } from "./RoomHeader/RoomHeader";
import ReactGA from 'react-ga4';

import {
  Content,
  LeftPanel,
  LeftSidebar,
  Main,
  PageLayout,
  RightSidebar,
  TopNavigation,
} from '@atlaskit/page-layout';
import Util from "../Util";
import EventSource from "../EventSource";
import JoinReqModal from "../Handlers/JoinReqHandler";
import { Event } from "../Handlers/Event";
import Handler from "../Handlers/Handler";
import InfoFlag, { handler as flagHandler } from "../Handlers/FlagHandler";

interface RoomProps { }

interface RoomState {
  users: User[];
  selectedMenu: string | null;
  chatCollapsed: boolean;
  topBarHeight: number;
}

export let handler: Handler = (evt: Event) => { return; } ;

export class Room extends React.Component<RoomProps, RoomState> {
  roomHeaderElem?: HTMLElement | null;
  evtSrc?: EventSource | undefined;

  constructor(props: RoomProps) {
    super(props);
    this.state = {
      users: [],
      selectedMenu: "main",
      chatCollapsed: true,
      topBarHeight: 63,
    };
    
    handler = this.onGroupChange.bind(this);
  }

  onGroupChange(evt: Event) {
    const users = this.state.users;
    const pos = users.findIndex(u => u.id === evt.subject.id);
    if (evt.eventType === "room.join") {
      if (pos < 0 || pos >= users.length) {
        users.push(evt.subject);
      }
    } else if (evt.eventType === "room.leave") {
      if (pos >= 0) {
        users.splice(pos, 1);
        flagHandler(evt);
      }
    }
    ReactGA.event({
      category: 'room',
      action: 'change:usercount',
      label: `${Util.extractDocId()}:${users.length}`,
  });
    this.setState(Object.assign({}, {users: users}));
  }

  onMenuChange(nm: string) {
    this.setState({
      selectedMenu: nm,
    });
  }

  onChatCollapseChange(status: boolean) {
    this.setState({ chatCollapsed: status })
  }

  componentDidMount(): void {
    this.roomHeaderElem = document.getElementById('room_header')
    window.addEventListener('resize', this.handleResize.bind(this))
    this.handleResize()

    // handle websocket events
    this.evtSrc = new EventSource(Util.extractDocId())
    ReactGA.event({
      category: 'room',
      action: 'finish:join',
      label: Util.extractDocId()
    });

    ReactGA.ga(
      'event', 
      'conversion', 
      {'send_to': 'AW-428449568/MylvCK7fqpYYEKC-pswB'}
    );

    ReactGA.event({
      action: 'conversion',
      category: 'pageview',
      value: 1,
      label: 'MylvCK7fqpYYEKC-pswB'
    })
  }

  handleResize() {
    if (this.roomHeaderElem) {
      this.setState({ topBarHeight: this.roomHeaderElem.getBoundingClientRect().height })
    }
  }

  render(): React.ReactNode {
    return (
        <PageLayout>
          <TopNavigation height={this.state.topBarHeight}>
            <RoomHeader users={this.state.users} title={Util.extractDocId()} />
          </TopNavigation>
          <LeftPanel isFixed={true} width={70}>
            <Menu onMenuChange={this.onMenuChange.bind(this)} />
          </LeftPanel>
          <Content>
            <LeftSidebar collapsedState="expanded">
              <RoomFocus showingContent={this.state.selectedMenu} />
            </LeftSidebar>
            <Main>
              <Editor evtSource={this.evtSrc}/>
            </Main>
            <RightSidebar width={this.state.chatCollapsed ? 50 : 400}>
              <Chat
                eventSource={this.evtSrc}
                chatCollapsed={this.state.chatCollapsed}
                onCollapseChange={(stat) => this.onChatCollapseChange(stat)}
              />
            </RightSidebar>
          </Content>
          <JoinReqModal/>
          <InfoFlag/>
        </PageLayout>

    );
  }
}
