import React from 'react';

import InfoIcon from '@atlaskit/icon/glyph/info';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { AutoDismissFlag, FlagGroup } from '@atlaskit/flag';
import { Event } from './Event';
import Handler from './Handler';

interface Flag {
    id: number;
    msg: string;
}

export let handler: Handler = (evt: Event) => { return; };

const InfoFlag = () => {
  const [flags, setFlags] = React.useState<Array<Flag>>([]);

  const addFlag = (msg: string) => {
    const newFlagId = flags.length + 1;
    const newFlags = flags.slice();
    newFlags.splice(0, 0, {id: newFlagId, msg: msg});

    setFlags(newFlags);
  };

  handler = (evt: Event) => {
    switch(evt.eventType) {
      case "room.leave":
        addFlag(`${evt.subject.id} left the room`);
        break;
      case "editor.lang":
        if (evt.data) {
          addFlag(`Language changed to ${evt.data}`);
        }
    }
  }

  const handleDismiss = () => {
    setFlags(flags.slice(1));
  };

  return (
    <div>
      <FlagGroup onDismissed={handleDismiss}>
        {flags.map((flag) => {
          return (
            <AutoDismissFlag
              appearance="info"
              id={flag.id}
              icon={
                <InfoIcon
                  label="Info"
                  secondaryColor={token('color.background.neutral.bold', N500)}
                />
              }
              key={flag.id}
              title={`${flag.msg}`}
            />
          );
        })}
      </FlagGroup>
    </div>
  );
};

export default InfoFlag;