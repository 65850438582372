import { Content, Main, PageLayout, TopNavigation } from "@atlaskit/page-layout";
import moment from "moment";
import React from "react";
import "./Landing.scss";
import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import { Link } from "react-router-dom";
import { UserInfo } from "local-codoc/types";
import { CodocClient } from "local-codoc";
import ReactGA from 'react-ga4';

interface LandingState {
    userinfo?: UserInfo;
    code?: string;
}

export class Landing extends React.Component<any, LandingState> {
    state: LandingState = { }
    async componentDidMount(): Promise<void> {
        ReactGA.send({hitType: 'pageview', page: window.location.pathname});
        this.setState(Object.assign({}, { userinfo: await CodocClient.me() }));
    }

    getTooltipContent(): React.ReactNode {
        return (
            <div>
                <p>{`${this.state.userinfo?.firstName} ${this.state.userinfo?.lastName}`}</p>
                <p>{this.state.userinfo?.id }</p>
            </div>
        )
    }

    joinCollab() {
        if (this.state.code) {
            if (/^[a-zA-Z0-9_\-]{9}$/.test(this.state.code)) {
                ReactGA.event({
                    category: 'doc',
                    action: 'init:join',
                    label: this.state.code
                });
                window.location.replace(`/d/${this.state.code}`);
            } else {
                alert("Invalid code.");
            }
        }
    }

    render(): React.ReactNode {
        return (
            <PageLayout testId="landing-content">
                <TopNavigation>
                    <div className="room-header">
                        <div className="nav-head">
                            <div className="codoc-logo"></div>
                        </div>
                        <div className="nav-detail">
                            <div className="header-actions">
                                <p>{moment(new Date().getTime()).format("HH:mm . ddd, DD MMM")}</p>
                                { this.state.userinfo == null?
                                    (<a id="SignInBtn" href="/login">Sign In</a>):
                                    (<div className="no-margin">
                                        <Tooltip content={this.getTooltipContent()}>
                                            <Avatar
                                                name={`${this.state.userinfo?.firstName} ${this.state.userinfo?.lastName}`}
                                                size="large"
                                                src={this.state.userinfo?.picture} />
                                        </Tooltip>
                                    </div>)
                                }
                                {/* <button className="btn main">Join a Document</button>
                                <button className="btn primary">New Document</button> */}
                            </div>
                        </div>
                    </div>
                </TopNavigation>
                <Content>
                    <Main>
                        <div className="landing-content">
                            <div className="column-half">
                                <div style={{width: '80%'}}>
                                    <h1>Realtime coding collabs.</h1>
                                    <h1>Free for everyone forever.</h1>
                                    <p className="hero">
                                        <span>Code with friends, conduct Interviews, or just jotdown some code with</span>
                                        <span style={{
                                            display: 'inline-block',
                                            background: 'url(logo/vector/isolated-layout.svg) no-repeat',
                                            backgroundSize: 'cover',
                                            width: 80,
                                            height: 23,
                                            backgroundPositionY: -25,
                                            backgroundPositionX: -5
                                            }}>

                                        </span>
                                    </p>

                                    <div className="btn-group">
                                        <Link to="/new">
                                            <button className="btn primary large">New Document</button>
                                        </Link>
                                        {/* <button className="btn main large">Join a Document</button> */}
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <span className="material-symbols-outlined">keyboard</span>
                                            </span>
                                            <input 
                                                value={this.state.code} 
                                                onChange={(e) => this.setState({code: e.target.value})}
                                                pattern="[a-zA-Z0-9_\-]{9}" 
                                                maxLength={9} 
                                                placeholder="xxx-yyy-zzz"
                                                autoComplete={"off"}
                                                spellCheck={false} />
                                            <button onClick={this.joinCollab.bind(this)}>Join</button>
                                        </div>
                                    </div>
                                    <p className="learn-more">
                                        <a href="">Learn more</a><span> about codoc.</span>
                                    </p>
                                </div>
                            </div>
                            <div className="column-half">
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '70%'}}>
                                    <img style={{borderRadius: '50%'}} src="banner.svg" width={'100%'}/>
                                    <small><span>Image by </span><a target="_blank" href="https://www.freepik.com/free-vector/pair-programming-concept-illustration_10838789.htm#query=coding%20illustrations&position=2&from_view=search&track=sph">storyset</a></small>
                                </div>
                            </div>
                        </div>
                    </Main>
                </Content>
            </PageLayout>
        )
    }
}