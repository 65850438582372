import axios from 'axios';
import Codoc from './codoc';
import {Delta, Doc, DocOptions, EditResp, UserInfo} from './types';

export default class Client {
  static async sync(
    docId: string,
    rev: number,
    deltas: ReadonlyArray<Delta>
  ): Promise<EditResp> {
    deltas = deltas.map(d =>
      Object.assign({}, {...d, value: btoa(d.value.toString())})
    );
    const req = {deltas: deltas};
    const url = `/api/docs/${docId}?rev=${rev}`;
    return axios
      .patch(Codoc.getServerUrl() + url, req)
      .then(resp => resp.data)
      .then((resp: EditResp) => {
        resp.deltas = resp.deltas.map(d =>
          Object.assign({}, {...d, value: atob(d.value.toString())})
        );
        return resp;
      });
  }

  static cachedMe: UserInfo | null = null;

  static async me(): Promise<UserInfo> {
    if (!!this.cachedMe) return this.cachedMe;

    console.log("Getting me info from server.");
    return axios.get(Codoc.getServerUrl() + '/api/me')
      .then(resp => { this.cachedMe = resp.data; return resp.data;});
  }

  static async newDoc(opts: DocOptions): Promise<Doc> {
    return axios.post(Codoc.getServerUrl() + '/api/docs', opts)
      .then(resp => resp.data);
  }

  static async getDoc(id: string): Promise<Doc> {
    return axios.get(Codoc.getServerUrl() + '/api/docs/' + id)
      .then(resp => resp.data);
  }

  static async allow(id: string, userId: string): Promise<any> {
    return axios.get(Codoc.getServerUrl() + `/api/docs/${id}/allow?userId=${userId}`)
      .then(resp => resp.data);
  }
  static async ask(id: string): Promise<any> {
    return axios.get(Codoc.getServerUrl() + "/api/ask?id=" + id)
      .then(resp => resp.data);
  }

  // TODO this may not be the right place to craete the websocket
  static join(id: string): WebSocket {
    return new WebSocket(Codoc.getServerUrl().replace("http", "ws") + `/api/docs/${id}/join`);
  }
}
