import { CodocClient } from "local-codoc";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./Ask.scss";

export const Ask = () => {
    const [waiting, setWaiting] = useState(false);
    const [error, setError] = useState(null);
    const [searchParams, _] = useSearchParams();
    const docId = searchParams.get("id");
    const nav = useNavigate();
    const id = docId || "";
    CodocClient.getDoc(id).then(() => nav(`/${id}`));

    const requestAccess = ((docId) => {
       return () => {
        if (docId == null) return;
        if (waiting) return;
        setWaiting(true);
        CodocClient
        .ask(docId)
        .then(_ => {setError(null);})
        .catch(e => {
            let msg = e.response?.data?.message;
            msg = !msg? "Something went wrong, try again later.": msg;
            setWaiting(false);
            setError(msg);
        });
       }
    })(docId);

    const busyCheck = ((docId, retrial) => {
        return function t() {
            if (docId == null) return;
            CodocClient.getDoc(docId)
            .then(_ => { setError(null); nav(`/${docId}`); })
            .catch(e => {
                console.log("Still waiting...");
                if (retrial > 0) {
                    const wait = Math.floor(Math.min(Math.pow(2, 8-retrial), 10));
                    retrial -= 1;
                    setTimeout(t, wait * 1000);
                }
            });
            return "";
        }
    })(docId, 7);

    return (
        <div className="interlude">
            {(!waiting?
                <p>
                    {(error == null?
                    <button onClick={requestAccess} className="btn primary large">Ask to join</button>
                    :
                    <p>{error}</p>)
                }
                </p>
                : (<div className="lds-ring"><div></div><div></div><div>{busyCheck()}</div><div></div></div>)
                )
            }
            <p style={{margin: '20px 0'}}>You'll join when someone lets you in.</p>
        </div>
    );
}