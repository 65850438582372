import Codoc from "local-codoc";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.scss";
import config from "./config.json";
import { router } from "./router";
import ReactGA from 'react-ga4';

const root = ReactDOM.createRoot(document.getElementById("root")!);

const baseUrl = config.baseUrl || "http://localhost:8080";
Codoc.setServerUrl(baseUrl);
ReactGA.initialize([
  {trackingId: 'G-QWTD3NT07W'}, 
  {trackingId: 'AW-428449568'}
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
