const PATH_REGEX = /\/d\/([a-zA-Z0-9_\-]+)$/;

export default class Util {
    /**
     * Extract docId from browser's url bar
     */
    
    static extractDocId(): string {
        const uri = window.location.href;
        let id = '';
        if (PATH_REGEX.test(uri)) {
            let match = PATH_REGEX.exec(uri);
            if (match) {
                id = match[1];
            }
        }

        return id;
    }

    static isEmpty(value: string, trim: boolean = false): Boolean {
        if (value == null) return true;
        if (trim) {
            value = value.trim();
        }
        return value.length <= 0;
    }
}