import React from "react";
import { ChatMessage } from "../../../models/chat";
import "./Chat.scss";
import Avatar from '@atlaskit/avatar';

import Comment, {
  CommentAuthor,
  CommentTime,
} from '@atlaskit/comment';
import moment, { Moment } from "moment";
import { CodocClient } from "local-codoc";
import { User } from "../../../models/user";
import Util from "../../Util";
import EventSource from "../../EventSource";
import { Event } from "../../Handlers/Event";
import Handler from "../../Handlers/Handler";

interface ChatProps {
  chatCollapsed: boolean;
  eventSource?: EventSource;
  onCollapseChange: (status: boolean) => void;
}

interface ChatState {
  messages: ChatMessage[];
  currentMessage: string;
  hintNewMsg: Boolean;
}

export let handler: Handler = (evt: Event) => { return; };

export class Chat extends React.Component<ChatProps, ChatState> {
  constructor(props: ChatProps) {
    super(props);
    // this.processMessages(dummyMessages);
    handler = this.handleMessageReceive.bind(this);
    this.state = {
      messages: [],
      currentMessage: "",
      hintNewMsg: false
    };
  }

  onMessageChange(message: string) {
    this.setState({ currentMessage: message });
  }

  toggleCollapse() {
    // chat is going to open
    if (this.props.chatCollapsed) this.setState({hintNewMsg: false});
    this.props.onCollapseChange(!this.props.chatCollapsed)

  }

  render(): React.ReactNode {
    if (this.props.chatCollapsed) {
      return <div className="collapsed-chat">
        <a><span onClick={() => this.toggleCollapse()} className="material-symbols-rounded chat-icon">
          forum
        </span>
        { this.state.hintNewMsg ? (<sup></sup>): <i></i>}
        </a>
      </div>
    }

    return (
      <div className="chat-grid">
        <div className="chat-head">
          <div className="chat-actions">
            <span onClick={() => this.toggleCollapse()} className="material-symbols-rounded chat-collapser">
              keyboard_double_arrow_right
            </span>
            <h2>Chat</h2>
          </div>
          {/* <div className="location">
            <p className="btn main">Room</p>
          </div> */}
        </div>
        <div className="chat-content">
          {this.state.messages.map((m, i) => this.renderMessage(m, i))}
          <p style={{
              display: this.state.messages.length == 0 ? 'flex': 'none',
              justifyContent: 'center',
              margin: 20,
              color: '#606e86',
              fontSize: 13,
              fontWeight: 400
            }}>
              Messages will appear here.
          </p>
        </div>
        <div className="chat-input">
          <form onSubmit={(e) => {e.preventDefault(); this.handleMessageSend();}}>
            <input
              type="text"
              placeholder="Say something!"
              onChange={(e) => {
                this.onMessageChange(e.target.value);
              }}
              value={this.state.currentMessage}
            />
            <button type="submit"
              className="chat-send"
            >
              <span className="material-symbols-rounded">send</span>
            </button>
          </form>
        </div>
      </div>
    );
  }

  renderMessage(m: ChatMessage, i: number): React.ReactNode {
    return (
      <div className="chat-message" key={'chat_msg_' + i}>
        <Comment
          avatar={<Avatar name={m.user.firstName + " " + m.user.lastName} src={m.user.picture}/>}
          author={<CommentAuthor>{m.user.firstName + " " + m.user.lastName}</CommentAuthor>}
          type={m.user.isMod ? "MOD" : m.user.tag}
          time={<CommentTime>{this.getTimeString(m.moment)}</CommentTime>}
          content={
            <p>
              {m.message}
            </p>
          }
        />
      </div>
    )
  }

  handleMessageSend() {
    if (Util.isEmpty(this.state.currentMessage, true)) return;
    CodocClient.me().then(
      u => {
        this.addMessage(u, this.state.currentMessage);
        this.props.eventSource?.sendMessage(u, this.state.currentMessage);
      })
    .then(_ => this.setState({currentMessage: ""}));
  }

  handleMessageReceive(evt: Event) {
    if (this.props.chatCollapsed) {
      this.setState({hintNewMsg: true});
    } else {
      this.setState({hintNewMsg: false});
    }
    this.addMessage(evt.subject, evt.data);
  }

  addMessage(u: User, msg: string) {
    const messages = this.state.messages.slice();
    messages.push({
      user: u,
      message: msg,
      time: new Date().getTime(),
    });

    this.setState({ messages: messages });
  }

  getTimeString(m: Moment | undefined): string {
    return m?.calendar() || ''
  }
}
