import { useEffect } from "react";
import {CodocClient} from "local-codoc";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
// import ErrorIcon from '@atlaskit/icon/glyph/error';
// import { R400 } from '@atlaskit/theme/colors';
// import { token } from '@atlaskit/tokens';


const NewDoc = () => {
    ReactGA.event({
        category: 'doc',
        action: 'init:new'
    });
    
    const navigate = useNavigate()

    useEffect(() => {
        CodocClient.newDoc({})
        .then(doc => {
            navigate(`/${doc.id}`, {replace: true})
        })
        .catch(err => {
            if (err.response?.status === 401) {
                window.location.replace("/login?next=/d/new");
            } else {
                navigate("/", {replace: true});
                // TODO add error flag
            }
        });
    });
        
    return (
        <p>Creating new document ...</p>
    );
}

export default NewDoc;