import { css, jsx } from '@emotion/react';

import Button from '@atlaskit/button/standard-button';

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import { useCallback, useState } from 'react';
import { Event } from './Event';
import Handler from './Handler';
import { CodocClient } from 'local-codoc';
import Util from '../Util';

const boldStyles = css({
  fontWeight: 'bold',
});

export let handler: Handler;

export default function JoinReqModal() {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);
  
    let [evt, setEvt] = useState({subject: {id: ""}});
    handler = (msg: Event) => { setEvt(msg); openModal()};
  
    const acceptReq = () => {
        CodocClient.allow(Util.extractDocId(), evt.subject.id);
        closeModal();
    }

    return (
      <div>
        <ModalTransition>
          {isOpen && (
            <Modal onClose={closeModal}>
              <ModalHeader>
                <ModalTitle>Join Request</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <p>Someone with email <b>{evt.subject.id}</b> wants to join.</p>
                <p><i>Make sure you know the requester.</i></p>
              </ModalBody>
              <ModalFooter>
                <Button appearance="subtle" onClick={closeModal}>
                  Reject
                </Button>
                <Button appearance="primary" onClick={acceptReq} autoFocus>
                  Accept
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </ModalTransition>
      </div>
    );
  }