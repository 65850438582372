import { CodocClient } from "local-codoc";
import { createBrowserRouter } from "react-router-dom";
import { Ask } from "./Components/Ask";
import { Landing } from "./Components/Landing";
import { Layout } from "./Components/Layout/Layout";
import NewDoc from "./Components/NewDoc";
import Protected from "./Components/Protected";
import { Room } from "./Components/Room/Room";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Landing />,
      },
      {
        path: "/new",
        element: <NewDoc />,
      },
      {
        path: "/ask",
        element: <Ask />,
        caseSensitive: true
      },
      {
        path: "/:id",
        element: 
          <Protected 
            guardFn={
              async id => {
                try {
                  await CodocClient.getDoc(id); 
                } catch (e: any) {
                  if (e.response?.data?.goto) {
                    return e.response.data.goto;
                  };
                  return "/login?next=/d/ask?id="+ id;
                }
              }
            }>
            <Room />
          </Protected>,
        caseSensitive: true
      },
    ],
  },
], {
  basename: "/d"
});
