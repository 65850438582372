import type {Range, Delta} from './types';
import {Cmd} from './types';

/**
 * Calculates effective delta, after resolving conflicts with unseen changes
 * @remarks Uses Operational transform to resolve the conflict.
 * @param unseen, changes which are locally present, but not committed to server
 * @param delta, change which was done without the context of unseen
 * @returns effective delta
 *
 * TODO:Anjan: Handle for @link Cmd.mov, @link Cmd.sel
 * @beta
 */
export function Consensus(unseen: ReadonlyArray<Delta>, delta: Delta): Delta {
  let {pos, value} = delta;
  const {op} = delta;
  unseen.forEach(i => {
    if ((i.op === Cmd.add || op === Cmd.add) && i.pos <= pos) {
      pos += (i.op === Cmd.add ? 1 : -1) * i.value.length;
    } else if (i.op === Cmd.del && op === Cmd.del) {
      const a: Range = {start: i.pos, len: i.value.length};
      const b: Range = {start: pos, len: value.length};
      if (Overlap(a, b)) {
        if (i.pos <= pos) {
          value = value.substring(i.pos + i.value.length - pos);
          pos = i.pos;
        } else {
          value = value
            .substring(0, i.pos - pos)
            .concat(value.substring(i.pos + i.value.length - pos));
        }
      } else if (i.pos < pos) {
        pos -= i.value.length;
      }
    }
  });

  return {op, pos, value};
}

function Overlap(a: Range, b: Range) {
  return a.start < b.start + b.len && b.start < a.start + a.len;
}
