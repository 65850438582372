import React from "react";
import { Link } from "react-router-dom";
import "./Menu.scss";

interface MenuProps {
  onMenuChange: (nm: string) => any;
}

interface MenuState {
  selected: string;
}

export class Menu extends React.Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      selected: "main",
    };
  }

  changeMenu(nm: string) {
    if (this.state.selected !== nm) {
      this.props.onMenuChange(nm);
      this.setState({
        selected: nm,
      });
    }
  }

  render(): React.ReactNode {
    return (
      <div className="menu">
        <a href="/" title="Codoc Home"><div className="codoc-icon"></div></a>
        <div className="links">
          <div onClick={() => this.changeMenu("main")}>
            <span
              className={
                "material-symbols-rounded icon" +
                (this.state.selected === "main" ? " selected" : "")
              }
            >
              speaker_notes
            </span>
          </div>
          {/* <div onClick={() => this.changeMenu("results")}>
            <span
              className={
                "material-symbols-rounded icon" +
                (this.state.selected === "results" ? " selected" : "")
              }
            >
              wine_bar
            </span>
          </div> */}
        </div>
      </div>
    );
  }
}
