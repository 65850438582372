import React from "react";
import "./RoomFocus.scss";

export interface RoomFocusProps {
  showingContent: string | null;
}

export class RoomFocus extends React.Component<RoomFocusProps, {}> {
  mainContent(): React.ReactNode {
    return (
      <div className="focus-content">
        <h2 className="head">Collaborate</h2>
        <p className="exp">
          Feel free to invite others either by 
        </p>
        <p className="exp">
          <ul style={{paddingLeft: 20}}>
            <li>Sharing the code directly</li>
            <li>Or by using the invite button on top right</li>
            <li>You can also share the url</li>
          </ul>
        </p>
        <p className="exp">
          Use chat in far right to communicate.
        </p>
        <h2 className="head">Get Started</h2>
        <p className="exp">
          Select any language or theme of your choice.
        </p>
        <h2 className="head">Support</h2>
        <p className="exp">
          Write us to at <a href="mailto:codocdev@gmail.com">codocdev@gmail.com</a> for any support or feedback.
        </p>
      </div>
    );
  }

  anotherContent(): React.ReactNode {
    return (
      <div className="focus-content">
        <h2 className="head">Result Announcement</h2>
        <p className="exp">
          This section will show all the results of the contests that are
          running on the website and is currently ready to be announced to the
          participants and public.
        </p>
        <p className="exp">
          Feel free to drop your comments for the winners of each competition
          and cheer them on for the upcoming tournies.
        </p>
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <div className="room-focus">
        {this.props.showingContent === "main"
          ? this.mainContent()
          : this.anotherContent()}
      </div>
    );
  }
}
