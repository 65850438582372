/**
 * 0. buffer abstraction
 * 1. conflict resolution - logic only
 * 2. edit session - maintain a local editor state, send/receive updates
 * 3. clients
 * 4. network channel - factory? providing wamp, ws channels
 * 5. ace adapter over edit session - mainly converting from 1D model to 2D model.
 */
interface _Range {
  start: number;
  len: number;
}

interface _Doc {
  id: string;
  start?: number;
  end?: number;
  owner?: string;
}

interface _DocOptions {
  owner?: string;
  start?: number;
  end?: number;
  value?: string;
}

/**
 * Symbol string; string of symbols
 * @remarks `string` is also assignable to this interface
 */
interface _Sstring {
  substring(start: number, end?: number): Sstring;
  slice(start: number, end?: number): Sstring;
  concat(...args: Sstring[]): Sstring;
  charAt(pos: number): string; // returns a symbol
  length: number;
  toString(): string;
}

interface _Delta {
  op: Cmd;
  pos: number;
  value: Sstring;
  owner?: string;
}

interface _Identity {
  id: string;
  type: string;
  provider: string;
}

export enum Cmd {
  add = 0,
  del,
  mov,
  sel,
}

interface UnaryFn<Type> {
  (arg: Type): Promise<void>;
}

export type Range = Readonly<_Range>;
export type Sstring = Readonly<_Sstring>;
export type Delta = Readonly<_Delta>;
export type Doc = Readonly<_Doc>;
export type DocOptions = Readonly<_DocOptions>;
export type Identity = Readonly<_Identity>;
export type CallbackFn = UnaryFn<ReadonlyArray<Delta>>;

export type EditResp = {
  rev: number; 
  deltas: ReadonlyArray<Delta>
};

export type UserInfo = {
  id: string; 
  firstName: string; 
  lastName: string; 
  picture: string
};
