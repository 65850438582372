import React from "react";
import "ace-builds";
import AceEditor from "react-ace";

import { editorModes, editorThemes, editorThemesDark, editorThemesLight } from "./data";
import { config } from "ace-builds";

import "./Editor.scss";
import Select from "@atlaskit/select";
import Codoc, { CodocEditSession } from "local-codoc";
import { Cmd } from "local-codoc/types";
import Util from "../../Util";
import { Event } from "../../Handlers/Event";
import Handler from "../../Handlers/Handler";
import EventSource from "../../EventSource";
import { handler as flagHandler } from "../../Handlers/FlagHandler";

config.set(
  "basePath",
  "https://cdn.jsdelivr.net/npm/ace-builds@1.12.1/src-noconflict/"
);


interface EditorProps {
  evtSource?: EventSource | null
}

interface EditorState {
  editorHeight: string;
  mode?: {value: string, label: string} | null;
  theme?: {value: string, label: string} | null;
  v: number;
  code: string;
}

export let handler: Handler = (evt: Event) => { return; }

export class Editor extends React.Component<EditorProps, EditorState> {
  
  constructor(props: EditorProps) {
    super(props);
    this.state = {
      editorHeight: "500px",
      mode: editorModes.find(x => x.value === "java"),
      theme: editorThemesLight.find(x => x.value === "chrome"),
      v: 0,
      code: ''
    };
    this.editor = React.createRef();
    this.session = null;
    handler = this.onConfigChange.bind(this);
  }

  session: CodocEditSession | null
  editor: any
  internal = false
  autoSync = true

  componentDidMount() {
    const taste = window.localStorage.getItem("theme");
    if (taste) {
      const x = editorThemesLight.concat(editorThemesDark)
        .find(x => x.value == taste);
      this.setState({theme: x});
    }
    
    const id = Util.extractDocId()
    this.session = new CodocEditSession({id: id}, this.autoSync);
    const session = this.editor.current.editor.session;

    this.session.subscribe(async deltas => {
      this.internal = true;
      deltas.forEach(delta => {
        const aceDelta: any = this._convertToDelta(delta);
        if (aceDelta.action === "insert") {
          session.insert(aceDelta.start, aceDelta.lines.join("\n"));
        } else if (aceDelta.action === "remove") {
          session.remove({start: aceDelta.start, end: aceDelta.end});
        }
      });
      this.internal = false;
    });
  }

  onConfigChange(evt: Event) {
    if (evt.eventType === "editor.lang") {
      const mode = editorModes.find(x => x.value === evt.data);
      if (mode) {
        this.setState({mode: mode});
        flagHandler(evt);
      }
    } else if (evt.eventType === "editor.theme") {
      const theme = editorThemesLight.concat(editorThemesDark).find(x => x.value === evt.data);
      if (theme) {
        this.setState({theme: theme});
      }
    }
  }

  onChange(newValue: any, evt: any) {
    this.setState(Object.assign(this.state, { code: newValue }));
    if (this.internal) {
      return;
    }
    // this.setState(Object.assign(this.state, { code: newValue }));
    //Convert event to codoc format
    evt = this._convert(evt);

    // Sync with backend
    if (evt[0] === Cmd.add) {
      this.session?.insert(evt[1], evt[2]);
    } else if (evt[0] === Cmd.del) {
      this.session?.remove(evt[1], evt[2]);
    }
    if (!this.autoSync) {
      this.session?.sync();
    }
  }

  _convert(evt: any) {
    const session = this.editor.current.editor.session;
    const lines = session.getLines(0, evt.start.row-1);
    const count = lines.reduce((p: any, c: any) => p + c.length, 0) + lines.length;
    const change = evt.lines.join("\n");
    let a = Cmd.sel;
    if (evt.action === "insert") {
      a = Cmd.add;
    } else if (evt.action === "remove") {
      a = Cmd.del;
    }

    return [a, count + evt.start.column, change];
  }

  _convertToDelta(nativeEvt: any) {
    const session = this.editor.current.editor.session;
    let {op, pos, value} = nativeEvt;
    let text = value;
    const lines = session.getLines(0, Infinity);

    let delta: any = { };
    delta.action = (op === Cmd.add)?'insert':'remove';
    delta.lines = text.split("\n");
    let row = 0;
    while (row < lines.length && pos - lines[row].length - 1 >= 0) {
      pos -= lines[row].length + 1;
      row += 1;
    }

    delta.start = {row: row, column: pos};
    delta.end = {row: row + delta.lines.length-1, column: delta.lines[delta.lines.length-1].length};
    if (delta.end.row === delta.start.row) {
      delta.end.column += pos;
    }

    return delta; 
  }

  render(): React.ReactNode {
    return (
      <div className="editor-grid">
        <div className="editor">
          <AceEditor
            key={"codoc"}
            ref={this.editor}
            mode={this.state.mode?.value}
            theme={this.state.theme?.value}
            width="100%"
            height="100%"
            fontSize="15px"
            onChange={this.onChange.bind(this)}
            name="room-editor-sdddd"
            // value={exampleCode}
            editorProps={{ $blockScrolling: true }}
            onLoad={function(editor){ editor.renderer.setPadding(4); editor.renderer.setScrollMargin(4, 4, 4, 4); }}
          />
        </div>
        <div className="editor-settings">
          {/* <div className="settings"> */}
            <Select
              placeholder="Choose a Language"
              isSearchable={true}
              options={editorModes}
              menuPlacement={'top'}
              value={this.state.mode}
              className="lang-select"
              isMulti={false}
              onChange={(opt) => { 
                this.props.evtSource?.setLang(opt?.value);
                this.setState({mode: opt}); 
              }}
              appearance="subtle"
            />
            <Select
              placeholder="Editor Theme"
              isSearchable={true}
              options={editorThemes}
              menuPlacement={'top'}
              value={this.state.theme}
              className="lang-select"
              isMulti={false}
              onChange={(opt) => {
                this.setState({theme: opt});
                window.localStorage.setItem("theme", opt?.value || "");
              }}
              appearance="subtle"
            />
          {/* </div> */}
          {/* <div className="editor-actions">
            <button className="btn main">Run</button>
            <button className="btn primary">Submit</button>
          </div> */}
        </div>
      </div>
    );
  }
}
