export default class Codoc {
  private static serverUrl = 'http://localhost:8080';

  static getServerUrl(): string {
    return this.serverUrl;
  }

  static setServerUrl(url: string) {
    this.serverUrl = url;
  }
}
