import { CodocClient } from "local-codoc";
import { User } from "../models/user";
import { Event } from "./Handlers/Event";
import Handler from "./Handlers/Handler";
import { handler as joinReqHandler } from "./Handlers/JoinReqHandler";
import { handler as groupHandler } from "./Room/Room";
import { handler as chatHandler } from "./Room/Chat/Chat"
import { handler as editorConfigHandler } from "./Room/Editor/Editor";

// Currently handlers are hardcoded
export default class EventSource {
    ws?: WebSocket | null
    docId: string
    callbacks: { [ name: string]: Handler[] } = {
        "room.joinreq": [joinReqHandler],
        "room.join": [groupHandler],
        "room.leave": [groupHandler],
        "chat.recv": [chatHandler],
        "editor.lang": [editorConfigHandler],
        "editor.theme": [editorConfigHandler]
    }

    constructor(docId: string) {
        this.docId = docId;
        this.open();
    }

    private handle(evt: MessageEvent<any>) {
        const txt = evt.data as string;
        txt.split("\n").forEach(l => {
            const msg = JSON.parse(l);
            this.callbacks[msg.eventType]?.forEach(c => c(msg));
        });
    }

    private open() {
        const that = this;
        this.ws = CodocClient.join(this.docId);
        this.ws.addEventListener("message", this.handle.bind(that));
        this.ws.addEventListener("close", () => setTimeout(this.open.bind(that), 4));
    }

    public sendMessage(u: User, msg: string) {
        const evt: Event = {eventType: "chat.send", subject: u, data: msg};
        this.ws?.send(JSON.stringify(evt));
    }

    public setLang(lang?: string) {
        CodocClient.me().then(u => {
            const evt: Event = {eventType: "editor.lang", subject: u, data: lang};
            this.ws?.send(JSON.stringify(evt));
        });
    }
}