import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// If returned values is a non-false value
// it is treated as redirect path
interface RedirectFn {
  (docId: string): Promise<string|undefined>;
}

interface ProtectedProps {
  children: React.ReactElement;
  guardFn: RedirectFn;
}

const Protected = (props: ProtectedProps): React.ReactElement => {
  const [redirect, setRedirect] = useState("");
  const params = useParams();
 
  useEffect(() => {
    if (redirect === "") {
      props.guardFn(params["id"]?params["id"]:"")
      .then(to => {console.log("hola to", to); (to === undefined )? setRedirect("true"): setRedirect(to)})
      .catch(e => {console.log("hola", e); setRedirect("/login");})
    }
  }, []);

  if (redirect === "") return <h3>Loading...</h3>;
  if (redirect === "true") {
    return props.children;
  }
  setTimeout(
    () =>  window.location.replace(redirect),
    100
  );
  return <h3>Redirecting...</h3>
}

export default Protected;