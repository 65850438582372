import Client from './client';
import {Consensus} from './cons';
import {CallbackFn, Cmd, Delta, Doc, Sstring} from './types';

const POLL_INTERVAL = 4000;

export class EditSession {
  private buf: Sstring = '';
  private rev = 0;
  private doc: Doc;
  private closed = false;
  private timer: any = 0;

  private queue: Array<Delta> = [];
  private callbacks: Array<CallbackFn> = [];

  constructor(doc: Doc, autoSync = false) {
    this.doc = doc;

    if (autoSync) {
      this.pollSync.bind(this)();
    } else {
      this.syncDoc.bind(this)();
    }
  }

  /**
   *
   * @param pos ordinal position within buffer
   * @param value to be added at specified @param pos
   */
  insert(pos: number, value: Sstring): void {
    this._insert(pos, value);
    this.enqueue([{op: Cmd.add, pos, value}]);
  }

  /**
   *
   * @param pos ordinal position within buffer
   * @param value actual text to be removed, must exactly match from editor buffer
   * @returns false, if buffer[pos, pos+value.length] does not match value, buffer is not edited.
   */
  remove(pos: number, value: Sstring): boolean {
    if (this._remove(pos, value)) {
      this.enqueue([{op: Cmd.del, pos, value}]);
      return true;
    }
    return false;
  }

  /**
   * Subscribe for the updates
   * @param callback
   * @returns
   */
  subscribe(callback: CallbackFn): void {
    if (this.callbacks.indexOf(callback) === -1) {
      this.callbacks.push(callback);
    }
  }

  /**
   * Manually sync this doc
   */
  sync(): Promise<void> {
    return this.syncDoc();
  }

  /** Close edit session */
  close(): void {
    this.closed = true;
    this.callbacks = [];
    clearTimeout(this.timer);
  }

  /**
   *
   * @param start index in buffer
   * @param end Optional end index in buffer
   * @returns slice of the buffer
   */
  buffer(start: number, end?: number): Sstring {
    return this.buf.slice(start, end);
  }

  private enqueue(deltas: ReadonlyArray<Delta>) {
    this.queue = this.queue.concat(deltas);
  }

  private clearQueue() {
    this.queue = [];
  }

  private async syncDoc() {
    const deltas = this.compress(this.queue);
    return Client.sync(this.doc.id, this.rev, deltas)
      .then(resp => {this.clearQueue(); return resp;})
      .then(resp => this.applyDeltas(resp.rev, resp.deltas))
      .then(trans => this.callbacks.forEach(c => c(trans.deltas))); // notify callbacks
  }

  private async pollSync(): Promise<any> {
    return this.syncDoc
      .bind(this)()
      .then(
        _ =>
          !this.closed &&
          (this.timer = setTimeout(this.pollSync.bind(this), POLL_INTERVAL))
      ).catch(_ => !this.closed &&
        (this.timer = setTimeout(this.pollSync.bind(this), POLL_INTERVAL)));
  }

  private compress(deltas: ReadonlyArray<Delta>): ReadonlyArray<Delta> {
    const ans = [];
    let start = 0,
      end = -1;

    for (let i = 0; i < deltas.length - 1; i += 1) {
      start = i;
      while (
        i < deltas.length - 1 &&
        deltas[i].op === deltas[i + 1].op &&
        ((deltas[i].op === Cmd.add &&
          deltas[i].pos + deltas[i].value.length === deltas[i + 1].pos) ||
          (deltas[i].op === Cmd.del &&
            deltas[i + 1].pos + deltas[i + 1].value.length === deltas[i].pos))
      ) {
        i += 1;
      }
      end = i;
      ans.push(this.merge(deltas.slice(start, end + 1)));
    }

    if (end < deltas.length - 1) {
      ans.push(deltas[end + 1]);
    }

    return ans;
  }

  private _insert(pos: number, value: Sstring) {
    const left = this.buf.substring(0, pos);
    const right = this.buf.substring(pos);
    this.buf = left.concat(value).concat(right);
  }

  private _remove(pos: number, value: Sstring): boolean {
    if (this.buf.substring(pos, pos + value.length) !== value) return false;

    const left = this.buf.substring(0, pos);
    const right = this.buf.substring(pos + value.length);
    this.buf = left.concat(right);
    return true;
  }

  private _write(delta: Delta) {
    if (delta.op === Cmd.add) {
      this._insert(delta.pos, delta.value);
    } else if (delta.op === Cmd.del) {
      this._remove(delta.pos, delta.value);
    }
  }

  private async applyDeltas(
    rev: number,
    deltas: ReadonlyArray<Delta>
  ): Promise<{rev: number; deltas: ReadonlyArray<Delta>}> {
    const trans: Array<Delta> = [];
    this.rev = rev;
    deltas = deltas || [];

    // const id = (await this.auth.auth()).id;

    deltas.forEach(delta => {
      delta = Consensus(this.queue, delta);
      trans.push(delta);
      this._write(delta);
    });
    return {rev, deltas: trans};
  }

  private merge(deltas: ReadonlyArray<Delta>): Delta {
    if (deltas[0].op === Cmd.del) {
      deltas = [...deltas].reverse();
    }

    const buf = deltas.reduce((p: Sstring, v: Delta) => p.concat(v.value), '');
    return {op: deltas[0].op, pos: deltas[0].pos, value: buf};
  }
}
