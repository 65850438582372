import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-abap.js"
import "ace-builds/src-noconflict/mode-abc.js"
import "ace-builds/src-noconflict/mode-actionscript.js"
import "ace-builds/src-noconflict/mode-ada.js"
import "ace-builds/src-noconflict/mode-alda.js"
import "ace-builds/src-noconflict/mode-apache_conf.js"
import "ace-builds/src-noconflict/mode-apex.js"
import "ace-builds/src-noconflict/mode-applescript.js"
import "ace-builds/src-noconflict/mode-aql.js"
import "ace-builds/src-noconflict/mode-asciidoc.js"
import "ace-builds/src-noconflict/mode-asl.js"
import "ace-builds/src-noconflict/mode-assembly_x86.js"
import "ace-builds/src-noconflict/mode-autohotkey.js"
import "ace-builds/src-noconflict/mode-batchfile.js"
import "ace-builds/src-noconflict/mode-bibtex.js"
import "ace-builds/src-noconflict/mode-c9search.js"
import "ace-builds/src-noconflict/mode-c_cpp.js"
import "ace-builds/src-noconflict/mode-cirru.js"
import "ace-builds/src-noconflict/mode-clojure.js"
import "ace-builds/src-noconflict/mode-cobol.js"
import "ace-builds/src-noconflict/mode-coffee.js"
import "ace-builds/src-noconflict/mode-coldfusion.js"
import "ace-builds/src-noconflict/mode-crystal.js"
import "ace-builds/src-noconflict/mode-csharp.js"
import "ace-builds/src-noconflict/mode-csound_document.js"
import "ace-builds/src-noconflict/mode-csound_orchestra.js"
import "ace-builds/src-noconflict/mode-csound_score.js"
import "ace-builds/src-noconflict/mode-csp.js"
import "ace-builds/src-noconflict/mode-css.js"
import "ace-builds/src-noconflict/mode-curly.js"
import "ace-builds/src-noconflict/mode-d.js"
import "ace-builds/src-noconflict/mode-dart.js"
import "ace-builds/src-noconflict/mode-diff.js"
import "ace-builds/src-noconflict/mode-django.js"
import "ace-builds/src-noconflict/mode-dockerfile.js"
import "ace-builds/src-noconflict/mode-dot.js"
import "ace-builds/src-noconflict/mode-drools.js"
import "ace-builds/src-noconflict/mode-edifact.js"
import "ace-builds/src-noconflict/mode-eiffel.js"
import "ace-builds/src-noconflict/mode-ejs.js"
import "ace-builds/src-noconflict/mode-elixir.js"
import "ace-builds/src-noconflict/mode-elm.js"
import "ace-builds/src-noconflict/mode-erlang.js"
import "ace-builds/src-noconflict/mode-forth.js"
import "ace-builds/src-noconflict/mode-fortran.js"
import "ace-builds/src-noconflict/mode-fsharp.js"
import "ace-builds/src-noconflict/mode-fsl.js"
import "ace-builds/src-noconflict/mode-ftl.js"
import "ace-builds/src-noconflict/mode-gcode.js"
import "ace-builds/src-noconflict/mode-gherkin.js"
import "ace-builds/src-noconflict/mode-gitignore.js"
import "ace-builds/src-noconflict/mode-glsl.js"
import "ace-builds/src-noconflict/mode-gobstones.js"
import "ace-builds/src-noconflict/mode-golang.js"
import "ace-builds/src-noconflict/mode-graphqlschema.js"
import "ace-builds/src-noconflict/mode-groovy.js"
import "ace-builds/src-noconflict/mode-haml.js"
import "ace-builds/src-noconflict/mode-handlebars.js"
import "ace-builds/src-noconflict/mode-haskell.js"
import "ace-builds/src-noconflict/mode-haskell_cabal.js"
import "ace-builds/src-noconflict/mode-haxe.js"
import "ace-builds/src-noconflict/mode-hjson.js"
import "ace-builds/src-noconflict/mode-html.js"
import "ace-builds/src-noconflict/mode-html_elixir.js"
import "ace-builds/src-noconflict/mode-html_ruby.js"
import "ace-builds/src-noconflict/mode-ini.js"
import "ace-builds/src-noconflict/mode-io.js"
import "ace-builds/src-noconflict/mode-ion.js"
import "ace-builds/src-noconflict/mode-jack.js"
import "ace-builds/src-noconflict/mode-jade.js"
import "ace-builds/src-noconflict/mode-java.js"
import "ace-builds/src-noconflict/mode-javascript.js"
import "ace-builds/src-noconflict/mode-json.js"
import "ace-builds/src-noconflict/mode-json5.js"
import "ace-builds/src-noconflict/mode-jsoniq.js"
import "ace-builds/src-noconflict/mode-jsp.js"
import "ace-builds/src-noconflict/mode-jssm.js"
import "ace-builds/src-noconflict/mode-jsx.js"
import "ace-builds/src-noconflict/mode-julia.js"
import "ace-builds/src-noconflict/mode-kotlin.js"
import "ace-builds/src-noconflict/mode-latex.js"
import "ace-builds/src-noconflict/mode-latte.js"
import "ace-builds/src-noconflict/mode-less.js"
import "ace-builds/src-noconflict/mode-liquid.js"
import "ace-builds/src-noconflict/mode-lisp.js"
import "ace-builds/src-noconflict/mode-livescript.js"
import "ace-builds/src-noconflict/mode-logiql.js"
import "ace-builds/src-noconflict/mode-logtalk.js"
import "ace-builds/src-noconflict/mode-lsl.js"
import "ace-builds/src-noconflict/mode-lua.js"
import "ace-builds/src-noconflict/mode-luapage.js"
import "ace-builds/src-noconflict/mode-lucene.js"
import "ace-builds/src-noconflict/mode-makefile.js"
import "ace-builds/src-noconflict/mode-markdown.js"
import "ace-builds/src-noconflict/mode-mask.js"
import "ace-builds/src-noconflict/mode-matlab.js"
import "ace-builds/src-noconflict/mode-maze.js"
import "ace-builds/src-noconflict/mode-mediawiki.js"
import "ace-builds/src-noconflict/mode-mel.js"
import "ace-builds/src-noconflict/mode-mips.js"
import "ace-builds/src-noconflict/mode-mixal.js"
import "ace-builds/src-noconflict/mode-mushcode.js"
import "ace-builds/src-noconflict/mode-mysql.js"
import "ace-builds/src-noconflict/mode-nginx.js"
import "ace-builds/src-noconflict/mode-nim.js"
import "ace-builds/src-noconflict/mode-nix.js"
import "ace-builds/src-noconflict/mode-nsis.js"
import "ace-builds/src-noconflict/mode-nunjucks.js"
import "ace-builds/src-noconflict/mode-objectivec.js"
import "ace-builds/src-noconflict/mode-ocaml.js"
import "ace-builds/src-noconflict/mode-partiql.js"
import "ace-builds/src-noconflict/mode-pascal.js"
import "ace-builds/src-noconflict/mode-perl.js"
import "ace-builds/src-noconflict/mode-pgsql.js"
import "ace-builds/src-noconflict/mode-php.js"
import "ace-builds/src-noconflict/mode-php_laravel_blade.js"
import "ace-builds/src-noconflict/mode-pig.js"
import "ace-builds/src-noconflict/mode-plain_text.js"
import "ace-builds/src-noconflict/mode-powershell.js"
import "ace-builds/src-noconflict/mode-praat.js"
import "ace-builds/src-noconflict/mode-prisma.js"
import "ace-builds/src-noconflict/mode-prolog.js"
import "ace-builds/src-noconflict/mode-properties.js"
import "ace-builds/src-noconflict/mode-protobuf.js"
import "ace-builds/src-noconflict/mode-puppet.js"
import "ace-builds/src-noconflict/mode-python.js"
import "ace-builds/src-noconflict/mode-qml.js"
import "ace-builds/src-noconflict/mode-r.js"
import "ace-builds/src-noconflict/mode-raku.js"
import "ace-builds/src-noconflict/mode-razor.js"
import "ace-builds/src-noconflict/mode-rdoc.js"
import "ace-builds/src-noconflict/mode-red.js"
import "ace-builds/src-noconflict/mode-redshift.js"
import "ace-builds/src-noconflict/mode-rhtml.js"
import "ace-builds/src-noconflict/mode-robot.js"
import "ace-builds/src-noconflict/mode-rst.js"
import "ace-builds/src-noconflict/mode-ruby.js"
import "ace-builds/src-noconflict/mode-rust.js"
import "ace-builds/src-noconflict/mode-sac.js"
import "ace-builds/src-noconflict/mode-sass.js"
import "ace-builds/src-noconflict/mode-scad.js"
import "ace-builds/src-noconflict/mode-scala.js"
import "ace-builds/src-noconflict/mode-scheme.js"
import "ace-builds/src-noconflict/mode-scrypt.js"
import "ace-builds/src-noconflict/mode-scss.js"
import "ace-builds/src-noconflict/mode-sh.js"
import "ace-builds/src-noconflict/mode-sjs.js"
import "ace-builds/src-noconflict/mode-slim.js"
import "ace-builds/src-noconflict/mode-smarty.js"
import "ace-builds/src-noconflict/mode-smithy.js"
import "ace-builds/src-noconflict/mode-snippets.js"
import "ace-builds/src-noconflict/mode-soy_template.js"
import "ace-builds/src-noconflict/mode-space.js"
import "ace-builds/src-noconflict/mode-sparql.js"
import "ace-builds/src-noconflict/mode-sql.js"
import "ace-builds/src-noconflict/mode-sqlserver.js"
import "ace-builds/src-noconflict/mode-stylus.js"
import "ace-builds/src-noconflict/mode-svg.js"
import "ace-builds/src-noconflict/mode-swift.js"
import "ace-builds/src-noconflict/mode-tcl.js"
import "ace-builds/src-noconflict/mode-terraform.js"
import "ace-builds/src-noconflict/mode-tex.js"
import "ace-builds/src-noconflict/mode-text.js"
import "ace-builds/src-noconflict/mode-textile.js"
import "ace-builds/src-noconflict/mode-toml.js"
import "ace-builds/src-noconflict/mode-tsx.js"
import "ace-builds/src-noconflict/mode-turtle.js"
import "ace-builds/src-noconflict/mode-twig.js"
import "ace-builds/src-noconflict/mode-typescript.js"
import "ace-builds/src-noconflict/mode-vala.js"
import "ace-builds/src-noconflict/mode-vbscript.js"
import "ace-builds/src-noconflict/mode-velocity.js"
import "ace-builds/src-noconflict/mode-verilog.js"
import "ace-builds/src-noconflict/mode-vhdl.js"
import "ace-builds/src-noconflict/mode-visualforce.js"
import "ace-builds/src-noconflict/mode-wollok.js"
import "ace-builds/src-noconflict/mode-xml.js"
import "ace-builds/src-noconflict/mode-xquery.js"
import "ace-builds/src-noconflict/mode-yaml.js"
import "ace-builds/src-noconflict/mode-zeek.js"

const allModes = [
"abap",
"abc",
"actionscript",
"ada",
"alda",
"apache_conf",
"apex",
"applescript",
"aql",
"asciidoc",
"asl",
"assembly_x86",
"autohotkey",
"batchfile",
"bibtex",
"c9search",
"c_cpp",
"cirru",
"clojure",
"cobol",
"coffee",
"coldfusion",
"crystal",
"csharp",
"csound_document",
"csound_orchestra",
"csound_score",
"csp",
"css",
"curly",
"d",
"dart",
"diff",
"django",
"dockerfile",
"dot",
"drools",
"edifact",
"eiffel",
"ejs",
"elixir",
"elm",
"erlang",
"forth",
"fortran",
"fsharp",
"fsl",
"ftl",
"gcode",
"gherkin",
"gitignore",
"glsl",
"gobstones",
"golang",
"graphqlschema",
"groovy",
"haml",
"handlebars",
"haskell",
"haskell_cabal",
"haxe",
"hjson",
"html",
"html_elixir",
"html_ruby",
"ini",
"io",
"ion",
"jack",
"jade",
"java",
"javascript",
"json",
"json5",
"jsoniq",
"jsp",
"jssm",
"jsx",
"julia",
"kotlin",
"latex",
"latte",
"less",
"liquid",
"lisp",
"livescript",
"logiql",
"logtalk",
"lsl",
"lua",
"luapage",
"lucene",
"makefile",
"markdown",
"mask",
"matlab",
"maze",
"mediawiki",
"mel",
"mips",
"mixal",
"mushcode",
"mysql",
"nginx",
"nim",
"nix",
"nsis",
"nunjucks",
"objectivec",
"ocaml",
"partiql",
"pascal",
"perl",
"pgsql",
"php",
"php_laravel_blade",
"pig",
"plain_text",
"powershell",
"praat",
"prisma",
"prolog",
"properties",
"protobuf",
"puppet",
"python",
"qml",
"r",
"raku",
"razor",
"rdoc",
"red",
"redshift",
"rhtml",
"robot",
"rst",
"ruby",
"rust",
"sac",
"sass",
"scad",
"scala",
"scheme",
"scrypt",
"scss",
"sh",
"sjs",
"slim",
"smarty",
"smithy",
"snippets",
"soy_template",
"space",
"sparql",
"sql",
"sqlserver",
"stylus",
"svg",
"swift",
"tcl",
"terraform",
"tex",
"text",
"textile",
"toml",
"tsx",
"turtle",
"twig",
"typescript",
"vala",
"vbscript",
"velocity",
"verilog",
"vhdl",
"visualforce",
"wollok",
"xml",
"xquery",
"yaml",
"zeek"
]
export const editorModes = allModes.map(
	mode => ({ value: mode, label: mode.charAt(0).toUpperCase() + mode.slice(1) }) 
);

export const editorThemesLight = [
	{ value: "chrome", label: "Chrome"},
	{ value: "clouds", label: "Clouds"},
	{ value: "crimson_editor", label: "Crimson Editor"},
	{ value: "dawn", label: "Dawn"},
	{ value: "dreamweaver", label: "Dreamweaver"},
	{ value: "eclipse", label: "Eclipse"},
	{ value: "github", label: "GitHub"},
	{ value: "iplastic", label: "IPlastic"},
	{ value: "katzenmilch", label: "KatzenMilch"},
	{ value: "kuroir", label: "Kuroir"},
	{ value: "solarized_light", label: "Solarized Light"},
	{ value: "sqlserver", label: "SQL Server"},
	{ value: "textmate", label: "TextMate"},
	{ value: "tomorrow", label: "Tomorrow"},
	{ value: "xcode", label: "XCode"}
];
export const editorThemesDark = [
	{ value: "ambiance", label: "Ambiance"},
	{ value: "chaos", label: "Chaos"},
	{ value: "clouds_midnight", label: "Clouds Midnight"},
	{ value: "cobalt", label: "Cobalt"},
	{ value: "dracula", label: "Dracula"},
	{ value: "gob", label: "Greeon on Black"},
	{ value: "gruvbox", label: "Gruvbox"},
	{ value: "idle_fingers", label: "idle Fingers"},
	{ value: "kr_theme", label: "krTheme"},
	{ value: "merbivore", label: "Merbivore"},
	{ value: "merbivore_soft", label: "Merbivore Soft"},
	{ value: "mono_industrial", label: "Mono Industrial"},
	{ value: "monokai", label: "Monokai"},
	{ value: "pastel_on_dark", label: "Pastel on Dark"},
	{ value: "solarized_dark", label: "Solarized Dark"},
	{ value: "terminal", label: "Terminal"},
	{ value: "tomorrow_night", label: "Tomorrow Night"},
	{ value: "tomorrow_night_blue", label: "Tomorrow Night Blue"},
	{ value: "tomorrow_night_bright", label: "Tomorrow Night Bright"},
	{ value: "tomorrow_night_eighties", label: "Tomorrow Night 80s"},
	{ value: "twilight", label: "Twilight"},
	{ value: "vibrant_ink", label: "Vibrant Ink"}
];

export const editorThemes = [
  { label: 'Light Themes', options: editorThemesLight},
  { label: 'Dark Themes', options: editorThemesDark }
]