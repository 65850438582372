import AvatarGroup from "@atlaskit/avatar-group";
import Tooltip from "@atlaskit/tooltip";
import React from "react";
import { User } from "../../../models/user";
import "../RoomHeader/RoomHeader.scss";

interface RoomHeaderProps {
  users: User[];
  title?: string;
}

interface RoomHeaderState {
  copyTitle: string;
}

export class RoomHeader extends React.Component<RoomHeaderProps, RoomHeaderState> {
  state = { copyTitle: "Copy code"}

  render(): React.ReactNode {
    return (
      <div id="room_header" className="room-header">
        <div className="nav-head">
          <button className="back" onClick={window.history.back}>
            <span className="material-symbols-rounded">arrow_back</span>
          </button>
          <span>Code: </span>
          <h2 style={{fontSize: 15, marginLeft: 5}}>{ this.props.title }</h2>
          <Tooltip content={this.state.copyTitle}>
            <span 
              onClick={() => navigator.clipboard.writeText(this.props.title || "")
                .then(_ => this.setState({copyTitle: "Copied!"}))}
              onMouseEnter={() => this.setState({copyTitle: "Copy code"})}
              className="material-symbols-outlined content-copy">
                content_copy
            </span>
          </Tooltip>
        </div>
        <div className="nav-detail">
          <div className="online-users">
            <AvatarGroup appearance="stack" data={this.props.users.map( u => {
              return {
                key: u.id,
                email: u.id,
                name: u.firstName + " " + u.lastName,
                src: u.picture,
              };
            })}></AvatarGroup>
          </div>
          <div className="header-actions">
            <button className="btn main"
              onClick={
                () => {
                  window.open(
                    "mailto:test@example.com?" + 
                    "subject='Join me at Codoc'" + 
                    `&body='Hey, lets collaborate here ${window.location.href}'`
                  )
                }
              }>Invite</button>
          </div>
        </div>
      </div>
    );
  }
}
